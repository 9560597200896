$theme-colors: (
        primary: #3F7AEC,
        info: #00B2AE,
);

@import "./assets/css/tabler.min.css";
@import "~primereact/resources/themes/saga-blue/theme.css";
@import "~primereact/resources/primereact.min.css";
@import "~primeicons/primeicons.css";
@import './styles/mixin';

@font-face {
  font-family: 'Cairo';
  src: local('Cairo'), url(./assets/fonts/Cairo-Black.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Cairo-SemiBold';
  src: local('Cairo-SemiBold'), url(./assets/fonts/Cairo-SemiBold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Cairo-Bold';
  src: local('Cairo-Bold'), url(./assets/fonts/Cairo-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: local('OpenSans-Bold'), url(./assets/fonts/OpenSans-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: local('OpenSans-SemiBold'), url(./assets/fonts/OpenSans-SemiBold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'), url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Cairo-Regular';
  src: local('Cairo-Regular'), url(./assets/fonts/Cairo-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url(./assets/fonts/Poppins-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'), url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(./assets/fonts/Roboto-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
  font-display: swap;
}

html {
  margin: 0;
  min-height: 100%;
  padding: 0 !important;
}

body {
  width: 100%;
  height: 100%;
  background-color: #FCFCFC !important;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.App-wrapper {
  flex: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.App-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header{
  height: 80px;
  max-width: 100%;
  padding: 0 48px!important;
  flex-wrap: nowrap!important;

  @include for-tablet-portrait{
    height: 52px;
    padding: 0 10px!important;
  }
}

.above-header{
  height: 64px;
  width: 100%;
  background-color: #00B2AE;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include for-tablet-portrait{
    height: 35px;
    justify-content: center;
  }

  & > .container-fluid{
    margin-right: 100px!important;

    @include for-tablet-landscape{
      margin-right: 0!important;
    }

    & > a{
      text-decoration: none;
      color: #FFFFFF;
      font: normal normal bold 20px/22px Cairo;
      font-size: 20px;
      display: flex;
      align-items: center;

      @include for-tablet-portrait{
        font-size: 9px;
        font: normal normal bold 9px/10px Cairo;
        letter-spacing: 0.21px;
      }

      &:after{
        content: "";
        position: relative;
        display: inline-block;
        width: 1px;
        height: 16px;
        background: rgba(255, 255, 255, 0.7);
        margin: 0 15px;
      }
    }

    & > a:last-child{
      margin-right: 80px;
      &:after{
        display: none;
      }
    }

    @include for-tablet-landscape{
      justify-content: center!important;
    }
  }
}

.auth-cart-nav{
  padding-right: 52px;

  @include for-tablet-landscape{
    padding-right: 12px;
  }
}

.nav-btn {
  min-height: 2.8rem;
  height: auto;
  border-radius: 10px;
  font-family: "Cairo-semibold", sans-serif;

  & p{
    font: normal normal bold 20px Cairo;
  }
}

.btn-info {
  background-color: #00b2ae !important;
}

.form-group-custom {
  margin: 0.7rem .2rem !important;
}

#categorycarousel {
  .rec-item-wrapper {
    text-align: center !important;
    max-width: 280px !important;
  }

  .rec-carousel-item {
    max-width: 300px !important;
  }
}

#brandImagesCarousel {
  .rec-item-wrapper {
    width: 300px !important;
  }
}

.slideclass {
  margin: 0 3rem !important;
}

.redeem-btn {
  margin-top: 2px !important;
}

.img-category {
  width: 40%;
  height: 55px;
}

.g-modal-backdrop.show {
  opacity: .85 !important;
}

.p-carousel-next {
  box-shadow: rgb(51, 51, 51) 0 0 2px 0 !important;
}

.gifti-carousel-content {
  .p-carousel-item {
    flex: 1 0 12% !important;
  }
}

.slick-slider.slick-initialized {
  //padding-left: 10px;
}

.slick-next:before, .slick-prev:before {
  content: '';
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #fff;
  padding: 14px;
  border-radius: 50%;
}

.slick-next:before {
  background-image: url(./assets/media/icons/category_arrows/right_arrow_active.png) !important;
}

.slick-prev:before {
  background-image: url(./assets/media/icons/category_arrows/left_arrow_active.png) !important;
}

.slick-next.slick-disabled:before {
  opacity: 1;
  background-image: url(./assets/media/icons/category_arrows/right_arrow_gray.png) !important;
}

.slick-prev.slick-disabled:before {
  opacity: 1;
  background-image: url(./assets/media/icons/category_arrows/left_arrow_gray.png) !important;
}

.swiper-button-next.swiper-button-disabled {
  background-image: url(./assets/media/icons/category_arrows/right_arrow_gray.png) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.slick-arrow .slick-next {
  right: 0 !important;
  background-image: url(./assets/media/icons/category_arrows/right_arrow_active.png) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev.swiper-button-disabled {
  background-image: url(./assets/media/icons/category_arrows/left_arrow_gray.png) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next {
  right: 0 !important;
  background-image: url(./assets/media/icons/category_arrows/right_arrow_active.png) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  left: 0 !important;
  background-image: url(./assets/media/icons/category_arrows/left_arrow_active.png) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}


@media screen and (max-width: 1024px) {
  .img-category {
    width: 45%;
    height: 50px;
  }
}

@media screen and (max-width: 768px) {

  .swiper-button-next, .swiper-button-prev {
    display: unset !important;
  }

  .img-category {
    width: 30px !important;
    height: 30px !important;
  }
  .products_icons {
    font-size: 9px !important;
  }

  #categorycarousel {
    margin: 0;

    .rec-item-wrapper {
      width: 100% !important;
    }
  }

  .slideclass {
    margin: 0;
  }

  #brandImagesCarousel {
    .rec-carousel-item {
      width: 100% !important;
    }
  }

  .gifti-carousel-content {
    .p-carousel-next, .p-carousel-prev {
      display: none;
    }
  }
}
