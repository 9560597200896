@import '../../styles/mixin';


.d-md-none {
  //@include for-tablet-portrait{
  //  font-size: 7px;
  //}
  display: flex !important;
  justify-content: center;
  @media (min-width: 768px) {
    display: none !important;
  }
}

.bottom-social {
  display: flex !important;
  justify-content: space-between;
  max-width: 185px;
  @media (max-width: 767px) {
    display: none !important;
  }
}