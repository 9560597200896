.cart-first-column {
  position: -webkit-sticky;
  background: #ededf1 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 40px #0000000a;
  border-radius: 10px;
  position: sticky;
  top: 0;
}
.cart-currency {
  /* background-image: url('../../../assets/Rectangle-63.svg'); */
  /* background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/652/confectionary.png) repeat; */
  background: url(../../../assets/media/icons/Rectangle-63.svg);
  border-radius: 5px;
}

.ggp-parent-box {
  background-color: #cecece;
  border-radius: 6px;
}
.ggp-box {
  background-color: #ffffff;
  border-radius: 10px;
  color: #00b2ae;
}
.btn-persianGreen {
  background-color: #00b2ae;
  color: #ffffff;
  font-size: 0.8rem;
  font-family: "Cairo-Bold";
}
.btn-white {
  background-color: #ffffff;
  color: #00b2ae;
  font-size: 0.8rem;
  font-family: "Cairo-Bold";
  padding: 0 3rem;
}

.cart-inc-dec-box {
  border: 0.800000011920929px solid #b2b2c9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-list {
  padding: 10px;
  border: 1px solid #d4d4d4;
  margin-bottom: 10px;
  border-radius: 5px;
}
.item-details p {
  margin-bottom: 0;
  font-size: 13px;
}
span.cart-head {
  font-weight: 600;
  font-size: 20px;
}
.empty-cart-container {
  padding: 5rem;
}
.empty-cart-image {
  max-height: 22rem;
}
.empty-cart-header {
  font-family: Cairo-Bold;
}
.empty-cart-header {
  font-family: Cairo-Bold;
}
.start-gifting-btn {
  font-family: Cairo-semibold;
  padding: 0.8rem;
}
.tooltip-container{
  --tooltipBackground : #29313e !important;
  color: #fff !important;
}
@media screen and (max-width: 1365px) and (min-width: 768px) {
  span.count-name,
  span.count-symbol {
    font-weight: 500;
    font-size: 12px;
  }
  .cart-inc-dec-box button.btn.btn-link {
    padding: 2px 4px;
  }
  .item-details p {
    margin-bottom: 0;
    font-size: 13px;
  }
}
@media screen and (max-width: 768px) {
  .tooltip-container{
    width: 60% !important;
  }
  span.count-name,
  span.count-symbol {
    font-weight: 500;
    font-size: 13px;
  }
  .cart-inc-dec-box button.btn.btn-link {
    padding: 2px 4px;
  }
  .item-details p {
    margin-bottom: 0;
    font-size: 15px;
  }
  .item-details {
    padding-top: 20px;
  }
  .col-second {
    padding-top: 50px;
  }
}
