/*.slick-slider.slick-initialized {*/
/*    padding-left: 0;*/
/*}*/
@import '../../../../styles/mixin';

.slick-dots li.slick-active button:before {
    @include for-tablet-portrait{
        font-size: 7px;
    }
    @include for-phone-only{
        font-size: 7px;
    }
    color: #00B2AE;
    font-size: 16px;
}
.slick-dots li button:before {
    @include for-tablet-portrait{
        font-size: 7px;
    }
    @include for-phone-only{
        font-size: 7px;
    }
    color: #A5A5A5;
    font-size: 16px;
}




.companies-container {
    display: flex;
    align-items: center;
    align-self: flex-start;
    img {
        -webkit-transform: scale(0.82); /* Saf3.1+, Chrome */
        -moz-transform: scale(0.82); /* FF3.5+ */
        -ms-transform: scale(0.82); /* IE9 */
        -o-transform: scale(0.82); /* Opera 10.5+ */
        transform: scale(0.82);
    }
    @include for-tablet-landscape {
        margin-top: -10px;
    }

    @include for-phone-only {
        flex: 1
    }
}

.terms-and-conditions {
    color: #000;
    font-family: Cairo-Regular, sans-serif;
    position: absolute;
    bottom: 5px;
    right: 40px;
    font-size: 16px;
    line-height: 21.6px;
    letter-spacing: 0.72px;
    text-align: right;

    @include for-tablet-landscape {
        right: 0;
        bottom: 0;
        text-align: center;
    }

    @include for-phone-only {
        font-size: 9px;
        line-height: 11.6px;
        letter-spacing: 0.36px;
    }
}
