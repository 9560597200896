.triangle-right {
  width: 0;
  height: 0;
  border-top: 0 solid transparent;
  border-left: 560px solid #f0f9f9;
  border-bottom: 91px solid transparent;
}
.triangle-left {
  position: absolute;
  width: 0;
  height: 0;
  border-right: 1059px solid #00b2ae;
  border-bottom: 79px solid transparent;
  right: 0;
}

.header-row {
  max-width: 100%;
  overflow: hidden;
}
