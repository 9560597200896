
ul li, ol {
  color: #77838f;
  margin-bottom: 7px;
}

.arrow-sublist li {
  color: #77838f;
  display: flex;
  align-items: flex-start;
}

.arrow-sublist li::before {
  content: "➜";
  font-size: 14px;
  margin-inline-end: 10px;
  display: inline-block;
}

.arrow-sublist:dir(rtl) li::before {
  transform: scaleX(-1);
}

ol:dir(rtl) {
  list-style-type: arabic-indic;
}

.language-toggle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.language-toggle button {
  padding: 7px 12px;
  font-size: 14px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.language-toggle button:hover {
  background-color: #0056b3;
}
