@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap');

.faqMainWrap {
  .faq_title {
    color: #333333;
    font-family: 'Cairo', sans-serif;
    font-size: 40px;
    line-height: 64px;
  }

  span.frequently-asked-input.enqpage-search {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 11px 15px;

    svg {
      width: 26px;
      height: 26px;
      fill: #3A72EC;
    }
  }

  input.enquiry-searchbar {
    height: 50px;
    border-radius: 10px;
    color: #B2B2C9;
    font-family: 'Open Sans', sans-serif;
  }

  .help-faq {
    font-family: 'Open Sans', sans-serif;

    h5 {
      font-weight: 600;
      font-size: 18px;
    }

    p {
      font-size: 18px;
    }
  }

  .nav.nav-pills {
    border: 0;
    background-color: transparent;

    .nav-item {
      a.faq-list.nav-link {
        color: #636D79;
        padding: 0 0 0 60px;
        position: relative;
        font-weight: 400;

        &::before {
          content: "";
          width: 50px;
          height: 4px;
          background: #3A86FF;
          position: absolute;
          left: 0;
          top: 11px;
          opacity: 0;
        }
      }

      a.faq-list.nav-link.active {
        background-color: transparent;
        color: #3A86FF;
        font-family: OpenSans-Regular, sans-serif !important;
        font-weight: bold;

        &::before {
          opacity: 1;
        }
      }
    }
  }

  .tab-content {
    .card {
      margin-bottom: 30px !important;
      border-bottom: 1px solid !important;
      border-color: #ddd !important;
      border-radius: 10px !important;
      font-family: 'Open Sans', sans-serif;
    }

    .card-header {
      background-color: transparent;
      border-bottom: 0;
      color: #636D79;
      font-weight: bold;
      cursor: pointer;
    }

    .card-body {
      color: #A2A9B0;

      strong {
        color: #333;
      }
    }
  }

  .faq-text {
    font-family: Cairo-Bold, sans-serif;
    text-align: center;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
  }

  .faq-bootom-border {
    background-color: #00B2AE;
  }

  button {
    border-radius: 8px;
    opacity: 1;
    padding: 12px;
    font-size: 18px;
    text-align: left;
    font: normal normal bold 18px/19px Cairo;
    letter-spacing: 0;
  }
}
