#card-number{
  padding-top: 4%;
  padding-top: 0px !important;
}

.expiry_cvv{
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 24px;
  --tblr-gutter-x: 0rem;
}

#card-expiry{
  flex: 53;
  margin-right: 20px;
}

#card-cvv{
  flex: 35;
}

#card-number .StripeElement, #card-expiry .StripeElement, #card-cvv .StripeElement {
  padding-left: 40px;
}

.StripeElement.StripeElement--invalid
{
  border: 1px solid #d96830;
}

.StripeElement
{
  border: 1px solid #13395e;
  border-radius: 3px;
}

.StripeElement--webkit-autofill{
  background-color: #e8f0fe !important;
}


.payment-card{

  .custom{
    padding: 25px;
    padding-top: 65px;
    left: 130px;
    width: 50%;
    height: 350px;
    border-radius: 12px;
    background-color: #FFFFFF !important;
    font-family: Open Sans,bold;
    color: #03014C;
    box-shadow: 0px 2px 28px #3e357824;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  iframe {
    height: 38px !important;
  }

  label {
    display: block;
    height: 10px;
    color: #13395e;
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    margin: 8px 0;
  }

  .date-and-code {
    display: flex;
    margin-bottom: 8px;
  }

  .date-and-code > div:nth-child(1) {
    width: 60%;
  }

  .date-and-code > div:nth-child(2) {
    margin-left:15px;
    width: 40%;
  }

  .input-container {
    position: relative;
    display: flex;
    height: 40px;
  }

  .icon-container:last-child {
    right: 0;
  }

  .icon-container.payment-method {
    right: 0;
  }

  .input-container.card-number {
    margin-bottom: 8px;
  }

  .input-container.expiry-date {
    margin-right: 4px;
  }

  .input-container.cvv {
    margin-left: 4px;
  }

  .card-number-frame {
    padding-left: 40px;
  }

  .expiry-date-frame {
    padding-left: 40px;
  }

  .cvv-frame {
    padding-left: 40px;
  }

  div + button {
    margin-top: 8px;
  }

  .icon-container {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 26px;
    margin: 0 7px;
  }

  .icon-container.payment-method {
    transform: translateY(-50%) rotateY(90deg);
    transition: opacity 0.15s ease-out;
    opacity: 0;
    top: 50%;
  }

  .icon-container.payment-method.show {
    opacity: 1;
    transition: all 0.4s ease-out;
    transform: translateY(-50%) rotateY(0deg);
  }

  .icon-container.payment-method img {
    width: 100%;
  }

  [id$="-error"] {
    display: none;
  }

  .frame {
    opacity: 0;
  }

  .frame--activated {
    opacity: 1;
    margin-bottom: 16px;
    border: solid 1px #13395e;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
  }

  .frame--activated.frame--focus {
    border: solid 1px #13395e;
    box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
  }

  .frame--activated.frame--invalid {
    border: solid 1px #d96830;
    box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
  }

  .error-message {
    display: block;
    color: #c9501c;
    font-size: 0.9rem;
    margin: 8px 0 0 1px;
    font-weight: 300;
  }

  #pay-button {
    border: none;
    border-radius: 3px;
    color: #fff;
    font-weight: 500;
    height: 40px;
    width: 100%;
    background-color: #00B2AD;
    box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
  }

  #pay-button:active {
    background-color: #0b2a49;
    box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
  }

  #pay-button:hover {
    background-color:#008e8b;
    box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.4);
  }

  #pay-button:disabled {
    background-color: #697887;
    box-shadow: none;
  }

  #pay-button:not(:disabled) {
    cursor: pointer;
  }

  .success-payment-message {
    color: #13395e;
    line-height: 1.4;
  }

  .token {
    color: #b35e14;
    font-size: 0.9rem;
    font-family: monospace;
  }

  /**
    IE11-targeted optimisations
     */

  _:-ms-fullscreen,
  :root .icon-container {
    display: block;
  }

  _:-ms-fullscreen,
  :root .icon-container img {
    top: 50%;
    -ms-transform: translateY(-50%);
    position: absolute;
  }

  _:-ms-fullscreen,
  #icon-card-number,
  _:-ms-fullscreen,
  #icon-expiry-date,
  _:-ms-fullscreen,
  #icon-cvv {
    left: 7px;
  }

  #checkout-frames-card-number::-ms-clear {
    display: none;
  }


}


@media screen and (max-width: 768px) {
  .payment-card {
    .custom{
      padding: 25px;
      padding-top: 65px;
      left: 130px;
      width: 100%;
      height: 350px;
      border-radius: 12px;
      background-color: #FFFFFF !important;
      font-family: Open Sans,bold;
      color: #03014C;
      box-shadow: 0px 2px 28px #3e357824;
    }
  }
}

@media screen and (max-width: 576px) {
  .payment-card {
    .custom{
      padding: 25px;
      width: 100%;
      height: 350px;
      border-radius: 12px;
      background-color: #FFFFFF !important;
      font-family: Open Sans,bold;
      color: #03014C;
      box-shadow: 0px 2px 28px #3e357824;
    }
  }
}
