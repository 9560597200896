.notfound-container {
  padding: 3rem;
}

.oops-container {
  margin-top: -2rem;

  .oops-text {
    color: #77838F;
    font-family: Cairo, sans-serif;
    font-size: 14px;
  }
}

.go-to-homepage-btn {
  font-family: Cairo-semibold, sans-serif;
  padding: .8rem;
}
