@import '../../../styles/mixin';

.carosel_images .card-link {
  position: relative;
  top: 9px;
  .text-block {
    @media screen and (max-width: 1199px) {
      height: 40px;
      top: -8px;
    }
    @include for-tablet-landscape {
      height: 35px !important;
      top: -8px;
    }
    @include for-phone-only {
      top: -11px !important;
    }
    p {
      @media screen and (max-width: 1199px) {
        font-size: 13px !important;
      }
      @include for-tablet-landscape {
        font-size: 13px !important;
      }
      @include for-phone-only {
        font-size: 13px !important;
      }
    }
  }
}