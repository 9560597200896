@import '../../../styles/mixin';

.card-link {
  text-decoration: none !important;
}

.text-block {
  position: relative;
  background: #FFFFFF 0 0 no-repeat padding-box;
  top: -9px;
  box-shadow: 0 9px 30px #0000000D;
  height: 43px;
  border-radius: 0 0 10px 10px;
  vertical-align: center;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1199px) {
    height: 35px;
    top: -6px;
  }

  @include for-tablet-landscape {
    height: 30px !important;
    top: -8px;
  }
  @include for-phone-only {
    height: 25px;
    top: -6.5px;

  }

  p {
    font-family: Nunito-SemiBold, serif;
    font-size: 13.5px;
    color: #111111;
    margin: 0;
    letter-spacing: 0;
    padding: 0 5px 0 15px;
    line-height: 14px;
    @media screen and (max-width: 1199px) {
      font-size: 12.5px;
      line-height: 13px;
    }
    @include for-tablet-landscape {
      font-size: 12px;
    }
    @include for-phone-only {
      font-size: 11px;
      padding-left: 12px;
      line-height: 10px;
    }
  }
}