@import '../../styles/mixin';

.gifti-navbar {
  opacity: 1;
}

.gifti-brand {
  height: 3rem !important;
}

.location-dropdown-text {
  font-family: Cairo-Regular, sans-serif;
  color: #484265;
  opacity: 1;
  margin-bottom: -10px;
}

.nav-auth{
  display: flex;
  align-items: center;

  &:after{
    content: '';
    width: 1px;
    height: 23px;
    background-color: rgba(0, 0, 0, 0.2);
    display: inline-block;

    @include for-tablet-portrait{
      height: 15px;
    }
  }
}

/*# sourceMappingURL=Topbar.css.map */

.location-dropdown {
  .p-dropdown {
    background: unset !important;
    border: unset !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: unset !important
  }

  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    color: black !important;
    font-family: Cairo-Bold, serif;
    font-size: 20px;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: unset !important;
  }

  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: black !important;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

@include for-tablet-portrait {
  .location-dropdown {
    .p-dropdown .p-dropdown-label {
      font-size: 8px !important;
      padding: 0.5rem 0 0 5px;
      text-align: center;
    }
  }
  .location-dropdown-text{
    font: normal normal normal 8px/10px Cairo;
    letter-spacing: 0.16px;
    color: #484265;
  }
}

.link-container:hover {
  text-decoration: none;
}

@media screen and (max-width: 353px) {
  .nav-item .nav-btn {
    padding: 7px;
  }
}
