@import './styles/mixin';

@font-face {
  font-family: 'Cairo-Bold';
  src: url('./assets/fonts/Cairo-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito-Regular';
  src: url('./assets/fonts/Nunito-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito-SemiBold';
  src: url('./assets/fonts/Nunito-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Beauty';
  src: url('./assets/fonts/BeautyDemo.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Black';
  src: url('./assets/fonts/Gotham/gotham_black.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Light';
  src: url('./assets/fonts/Gotham/gotham_light.otf') format('opentype');
  font-display: swap;
}

.cardgifiti {
  background-color: #f2fbfb !important;
  border-radius: 1.25rem;
}

.cardgifiti-startgf {
  padding-bottom: 50px;
  background-color: #f2fbfb !important;
  box-shadow: 0 2px 28px #3e357824;
}

.cardgifiti-startgf1 {
  padding-bottom: 50px;
  box-shadow: 0 2px 28px #3e357824;
}

.cardgifiti-card {
  margin-top: 50px;
  padding: 40px 0;
  width: auto;
  background-color: #e8f8f8 !important;
}

.custom-card {
  background-color: #fff;
  background-clip: border-box;
  border-radius: 1.25rem;
}

.custom-card-startgifting {
  top: 40px;
  left: 130px;
  width: 1260px;
  height: 500px;
  background-color: #f2fbfb !important;
}

.card-text-container {
  padding-left: 50px;
}

.widgets_div .icon_div img {
  width: 85px;
  object-fit: none;
}

.card-text-btn button.custom-button svg {
  margin-left: 10px;
}

.card-text-btn button.custom-button {
  font-weight: 100;
  box-shadow: 0 2px 6px -1px #808080;
}

.card-text-btn .btn-outline-secondary {
  color: #000;
}

.gifticard-text {
  font-family: "Cairo-Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 39px;
  color: #11142d;
  letter-spacing: 0;
  line-height: 45px;
}

.giftiallcard-text {
  font-family: "Cairo-Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: rgba(17, 20, 45, 1);
}

.giftiallcard-text-a {
  font-family: "Cairo-Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(17, 20, 45, 1);
  @include for-phone-only {
    padding-left: 10px;
    margin-bottom: 0;
  }
}

.allGiftCard-box {
  padding: 40px 0;
  @include for-phone-only {
    padding: 20px 0;
  }
}

.allGiftCard-box .box img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.allGiftCard-box .allGiftCard {
  background: #f3f9f9;
}

.startgf-text {
  margin-top: 40px;
  font-family: "Cairo-Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: #11142d;
  opacity: 1;
}

.icons {
  margin-left: 40px;
}

.custom-font {
  line-height: 24px;
  margin-top: 5px;
  text-align: left;
  font-family: "Cairo-SemiBold", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #6e6e6e;
  letter-spacing: 0;
}

.startgiftimg {
  margin-bottom: 45px;
}

.amttext {
  padding-right: 10px;
  display: flex;
  align-items: center;
  font-family: Cairo-SemiBold, sans-serif;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0;
  color: #636363;
  opacity: 1;
}

.amttext2 {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-family: "Cairo-Bold", sans-serif !important;
  font-size: 35px;
  text-align: left;
  letter-spacing: 0;
  color: #11142d;
  opacity: 1;
  @include for-tablet-landscape {
    font-size: 24px;
  }
  @include for-phone-only {
    padding: 10px 0;
    font-size: 18px;
    margin-bottom: 0;
  }
}

.imgcards {
  height: 180px;
  width: 270px;
  margin: 18px 25px !important;
  border-radius: 12px;
}

.home-footer {
  padding-top: 45px !important;
  width: auto;
  background-color: #ffffff !important;
  box-shadow: 0px -2px 6px #00000029;
}

.Footer-text {
  font-family: OpenSans-Regular, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #06040a;
  line-height: 24px;
  opacity: 1;
}

.Gifti_Global_is {
  font-family: "Cairo-SemiBold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: rgba(6, 4, 10, 1);
  opacity: 1;
}

.footer-text-list {
  text-align: left;
  font-family: "Cairo-Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(84, 78, 93, 1);
}

.searchbar {
  margin-right: 100px;
}

.Navbar-button {
  opacity: 1;
  border-radius: 10px;
  height: 45px;
  font-family: "Cairo-semibold", sans-serif;
  width: 130px;
}

.search-field {
  width: 430px;
}

.search-button-b {
  color: white;
  background-color: white;
  border-color: #ced4da;
}

.Navbar-button-b {
  opacity: 1;
  border-radius: 10px;
  height: 45px;
  font-family: "Cairo-semibold", sans-serif;
  width: 248px;
}

.Navbar-button-c {
  opacity: 1;
  border-radius: 10px;
  height: 45px;
  font-family: "Cairo-semibold", sans-serif;
  font-style: normal;
  width: 112px;
}

div.container.redeem {
  max-width: 100% !important;
  @include for-tablet-landscape {
    padding: 0 !important;
  }
}

.login-redeem-container {
  width: 100%;
  max-width: 1180px;
  max-height: 856px;
  margin: 217px auto 262px;

  @include for-tablet-landscape {
    flex-direction: column;
    max-height: none;
    margin: 18px 0 0;
    padding: 0;
  }
}

.signup-redeem-container {
  width: 100%;
  max-width: 1290px;
  max-height: 980px;
  margin: 170px auto 185px;

  form.user {
    .name-input {
      width: 50%;
      flex: unset;
    }

    .lastname-input {
      width: 50%;
      flex: unset;
    }

    .row:first-child > div div {
      margin-bottom: 0 !important;
    }
  }

  @include for-tablet-landscape {
    flex-direction: column;
    max-height: none;
    margin: 15px 0 0;
    padding: 0;
  }
}

.login-card {
  padding: 0 25px;
  font-family: "Open Sans", sans-serif;
  margin: 50px auto;
  max-width: 400px;
  width: 100%;
  border-radius: 12px;
  background-color: #ffffff !important;
  color: #03014c;
  box-shadow: 0 2px 28px #3e357824;
}

.login-redeem-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 112px 0 70px;
  font-family: "Open Sans", sans-serif;
  max-width: 590px;
  width: 100%;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background-color: #ffffff !important;
  color: #03014c;
  box-shadow: 0 2px 28px #3e357824;

  @include for-tablet-landscape {
    padding: 0 16px;
    box-shadow: none;
    margin: 0 auto;
  }

  .login-text {
    font-size: 33px;
    padding-top: 39px;
    @include for-tablet-landscape {
      font-size: 26px;
      font-family: Cairo-Bold;
      color: #11142D;
      padding-top: 0 !important;
    }
  }

  .login-form {
    @include for-tablet-landscape {
      padding: 0 15px;
    }
  }

  .auth-divider-container {


    & > p.text {
      font: normal normal normal 14px/16px Work Sans;
      color: #222222;
      opacity: 0.4;
    }
  }
}

.login-redeem-info {
  width: 100%;
  max-width: 589px;
  height: 856px;
  background: url("./assets/media/images/login-redeem-bg.png") no-repeat;
  padding: 280px 45px 260px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  @include for-tablet-landscape {
    max-width: none;
    height: 523px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 145px 27px 0;
    background-size: cover;
    border-radius: 0;
  }

  img {
    @include for-tablet-landscape {
      margin-bottom: 60px !important;
      width: 200px;
      height: 33px;
    }
  }

  .welcome-title {
    font-size: 60px;
    color: #FFFFFF;
    font: normal normal bold 60px/82px Nunito-Regular;
    text-transform: uppercase;

    @include for-tablet-landscape {
      font: normal normal bold 40px/54px Nunito-Regular;
    }
  }

  .welcome-text {
    font: normal normal bold 33px/46px Open Sans;
    color: #FFFFFF;

    @include for-tablet-landscape {
      text-align: center;
      font: normal normal bold 20px/27px Open Sans;
    }
  }
}

.signup-redeem-info {
  width: 100%;
  max-width: 628px;
  height: 980px;
  background: url("./assets/media/images/signup-redeem-bg.png") no-repeat;
  padding: 257px 70px 220px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  @include for-tablet-landscape {
    max-width: none;
    height: 557px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 148px 39px 0;
    background-size: cover;
    border-radius: 0;
  }

  img {
    @include for-tablet-landscape {
      margin-bottom: 60px !important;
      width: 200px;
      height: 33px;
    }
  }

  .welcome-title {
    font-size: 60px;
    color: #FFFFFF;
    font: normal normal bold 60px/82px Nunito-Regular;

    @include for-small-desktop {
      font: normal normal bold 40px/50px Nunito-Regular;
    }

    @include for-tablet-landscape {
      text-align: center;
      font: normal normal bold 32px/43px Nunito-Regular;
      margin-bottom: 35px !important;
    }
  }

  .welcome-text {
    font: normal normal bold 33px/46px Open Sans;
    color: #FFFFFF;

    @include for-small-desktop {
      font: normal normal bold 30px/37px Open Sans;
    }

    @include for-tablet-landscape {
      text-align: center;
      font: normal normal bold 20px/27px Open Sans;
    }
  }
}

.Guestform-card {
  margin-top: 65px;
  top: 40px;
  left: 130px;
  max-width: 500px;
  max-height: 450px;
  min-height: 400px;
  width: auto;
  height: auto;
  border-radius: 12px;
  background-color: #ffffff !important;
  font-family: "Open Sans", sans-serif;
  color: #03014c;
  box-shadow: 0 2px 28px #3e357824;
  padding: 0 2em;
}

.profile-card {
  width: auto;
  border-radius: 12px;
  background-color: #ffffff !important;
  font-family: "Open Sans", sans-serif;
  color: #03014c;
  box-shadow: 0 2px 28px #3e357824;
  padding: 3rem;
  margin: 3rem;
}

.forgot-password {
  padding: 3rem;
  font-family: "Open Sans", sans-serif;
  margin: 3rem auto;
  max-width: 400px;
  width: 100%;
  border-radius: 12px;
  background-color: #ffffff !important;
  color: #03014c;
  box-shadow: 0 2px 28px #3e357824;
}

.login-text {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 27px;
  color: #03014c;
  opacity: 1;
}

.login-sub-text {
  font-family: "OpenSans-Regular", sans-serif;
  color: #03014c;
  opacity: 0.6;
}

.gift-auth-wrapper {
  width: 181px;
  height: 104px;
  border: 1px solid #00B2AE;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 53px auto 40px;
  align-items: center;

  @include for-tablet-landscape {
    margin: 11px auto 23px;
  }

  & > img {
    text-align: center;
    margin-top: 14px;
  }

  & > p {
    line-height: 30px;
    width: 100%;
    margin: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #00B2AE;
    font-family: "Open Sans", semibold;
    font-size: 10px;
    color: #FFFFFF;
    text-align: center;
  }
}

.google button.google-button span {
  display: none;
}

.btn-custom {
  width: 100%;
  font-family: "Cairo-bold", sans-serif;
  font-size: 18px;
  color: white;
}

button.google-button div {
  margin-right: 0 !important;
  background: unset !important;
  padding: 0 !important;
}

button.google-button {
  display: block !important;
  box-shadow: unset !important;
}

.social-btn {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 0 25px !important;


  @include for-tablet-landscape {
    padding-bottom: 28px !important;
  }

  & > div button {
    width: 222px !important;
    max-width: 100% !important;

    @include for-tablet-portrait {
      width: 130px !important;
    }
  }
}

.redio-forgot .form-group label,
.redio-forgot .form-group a,
td.login-bottom-text {
  color: gray !important;
  font-size: 14px;
}

.social-btn button {
  width: 159px !important;
  height: 40px;
  box-shadow: unset !important;
  padding: 0 !important;
  border: 1px solid #dfdfdf !important;
  border-radius: 10px !important;
  background-color: rgba(255, 255, 255, 1);
}

.facebook {
  width: 50%;
  text-align: right;
  padding-left: 18px;

  @include for-phone-only {
    padding-left: 8px;
  }
}

.facebook-login-btn {
  background-color: #fff;
}

.facebook-icon {
  width: 22px;
  height: 22px;
  color: #2299EC;
}

.google {
  width: 50%;
  padding-right: 18px;

  @include for-phone-only {
    padding-right: 8px;
  }
}

.social-btn button:hover {
  background: #e7e7e7 !important;
}

.social-btn button img {
  max-width: 27px !important;
  max-height: 27px !important;
  width: 100% !important;
  height: 100% !important;
}

.profile-input {
  margin-left: 75px;
}

.profile {
  padding-top: 78px;
}

.profile-inputfield {
  width: 190px !important;
  padding: 20px;
}

input::-webkit-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #b2b2c9;
  opacity: 1;
}

.icons_login {
  position: relative;
}

img.templatetheme.ml-5 {
  height: 102px;
  width: 121px;
  margin: 36px 0;
}

img.templatetheme1 {
  height: 125px;
  width: 223px;
}

img.templatetheme.ml-5:hover {
  border: 1px solid #00b2ae !important;
}

.icons_fields {
  margin: 0;
  padding-left: 60px;
  height: 50px;
  font-size: 13px;
  border-radius: 5px;
}

.icons_fields_b {
  height: 50px;
  font-size: 13px;
  border-radius: 5px;
}

.input_fields {
  height: 60px;
  width: 100%;
  border-radius: 5px;
  border-color: #D1D5DB;
  color: #000000;
  font: normal normal 600 14px/18px Open Sans;
}

.profile-iconsfields {
  padding-left: 60px;
  height: 46px;
}

.profile-iconsfields-a {
  height: 46px;
  padding-left: 30px;
}

.profile-iconsfields-b {
  padding-left: 24px;
  height: 46px;
}

.profile_icon {
  position: absolute;
  bottom: 15px;
  left: 24px;
}

.icon_img {
  position: absolute;
  bottom: 16px;
  left: 24px;
}

.icon_right {
  position: absolute;
  bottom: 16px;
  right: 24px;
}

.location {
  margin-top: -12px;
  margin-bottom: -12px;
  margin-left: 26px;
  font-family: "Cairo-Regular", sans-serif;
  color: #484265;
  opacity: 1;
}

.dashboard {
  margin-top: 300px;
}

.dasboard-a {
  margin-top: 250px;
}

.startgf-fields-text {
  font-family: "Cairo-SemiBold", sans-serif;
  font-size: 18px;
  margin-top: 5px;
  color: #11142d;
}

.giftslabs .form-check-input {
  margin-top: 0.4rem;
  margin-left: -12px;
  margin-right: 10px;
}

.startgf-fields-button {
  box-shadow: 0 2px 6px -1px #808080;
  padding: 10px 60px;
  border-radius: 10px;
}

.allgiftcard-text {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.box {
  min-height: max-content;
}

.box a {
  display: block;
  padding: 0 5% 5% 5%;
  text-align: center;
  width: 100%;
}

.box:hover {
  color: #fff;
  width: 100%;
  min-height: max-content;
  // display: flex;
  background-color: #e3e3e3;
}

.box:hover a {
  color: #fff;
  text-decoration: none;
  width: 100%;
}

.select-card-size1 {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.card-amnt {
  max-width: 600px;
  width: 100%;
}

.card-amnt button:focus {
  box-shadow: unset;
  background-color: #00b2ae;
  border-color: #00b2ae;
}

.butngroup {
  margin-left: 40px;
  align-items: center;
}

#cart .btn-primary {
  height: 48px;
  background-color: white;
  border: 1px solid white;
  color: black;
}

button.login.btn.btn-light.btn-lg {
  font-family: "Cairo-Bold", sans-serif;
  width: 146px;
  color: #00b2ae;
  background: white;
  margin-right: 30px;
  border-radius: 10px;
}

.cartcolor {
  padding: 20px;
  background-color: #ededf1;
}

.products_icons {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  margin-top: 6px;
  text-align: center;
  color: #000;
  font-weight: 600;
}

.allGiftCard-box button.rec-arrow {
  position: initial;
}

.allgiftcards-sec .cardgifiti-card {
  margin-top: 0 !important;
}

.giftiallcard-row-3 .gificards {
  padding-top: 0;
}

.giftiallcard-row-3 {
  padding: 40px 0;
}

#basic-nav-dropdown.dropdown-toggle.nav-link {
  color: #00b2ae !important;
  opacity: 1 !important;
  letter-spacing: 0;
  font-family: "Cairo-Bold", sans-serif;
}

.login-bottom-text {
  letter-spacing: 0;
  font-family: "OpenSans-Regular", sans-serif;
  font-size: 15px;
  opacity: 1;
  padding-top: 18px;
  margin-bottom: 0;

  & > a {
    font: normal normal bold 14px/19px Open Sans;
    color: #5381F8;
  }
}

.auth-divider-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 18px 0;

  .divider {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.16);
    }
  }

  .text {
    margin: 0;
    letter-spacing: 0;
    font-family: "OpenSans-Regular", sans-serif;
    opacity: 1;
    color: gray;
    font-size: 14px;
    padding: 0 10px;
  }
}

.validation-messages {
  color: red;
  text-align: center;
}

.validation-messages1 {
  color: red;
}

.cardlist {
  height: 168px;
  border: 0.8px solid #b2b2c9;
  border-radius: 10px;
}

.select-card-button {
  width: 104px;
  border-radius: 5px;
  border-color: #b2b2c9;
  color: #11142d;
  font-size: 20px;
}

.selects-card-button-a {
  width: 180px;
  border-radius: 10px;
}

.selects-card-button-b {
  width: 150px;
  border-radius: 10px;
}

.select-card-size {
  height: 260px;
}

.select-card-text {
  font-family: "Cairo", sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.select-card-text-lg {
  font-family: "Cairo", sans-serif;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 5px;
}

.select-card-text-lg1 {
  font-family: "Cairo", sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #00b2ae;
  width: 344px;
  height: 217px;
}

.select-card-text {
  font-family: "Cairo-Bold", sans-serif;
}

.nav-btn-link {
  text-decoration: none;
}

.nav-login {
  opacity: 1;
  border-radius: 10px;
  font-family: "Cairo-semibold", sans-serif;
}

.card-text-align {
  padding-top: 70px;
  padding-left: 80px;
}

.widgets_div .icon_div {
  display: inline-block;
  vertical-align: middle;
}

.widgets_div .icon_div span i {
  font-size: 24px;
}

.widgets_div .text_div {
  display: inline-block;
  vertical-align: middle;
  font-family: "Cairo-semibold", sans-serif;
  max-width: 260px !important;
}

.widgets_div .text_div span {
  color: #58666e;
}

.widgets_div .text_div span + span {
  color: #747f85;
  font-size: 11px;
}

.icon-margin {
  padding: 0 0 0 50px;
}

.transparentButton {
  border: unset !important;
  background-color: transparent;
  margin: 0 auto !important;
  width: 129px;
  min-height: 169px;
  text-decoration: none;
}

button.rec-arrow {
  background: #fff !important;
  color: #000 !important;
}

.rec-arrow-left {
  left: 22px;
}

.rec-arrow-right {
  right: 66px;
}

/* square buttons */
.rec.rec-arrow {
  background-image: url(./assets/media/icons/category_arrows/left_arrow_active.png);
  align-self: center !important;
  color: #0000;
}

// .rec .rec-item-wrapper {
//   width: 100% !important;
// }

.rec .rec-arrow-right {
  background-image: url(./assets/media/icons/category_arrows/right_arrow_active.png);
  align-self: center !important;
  color: #0000;
}

/* round buttons on hover */
.rec.rec-arrow:hover {
  background-image: url(./assets/media/icons/category_arrows/left_arrow_gray.png);
  align-self: center;
  color: #0000;
}

.rec .rec-arrow-right:hover {
  background-image: url(./assets/media/icons/category_arrows/right_arrow_gray.png);
  color: #0000;
}

.m20 {
  margin: 58px 57px 58px 3px;
  font-family: "Cairo-Bold", sans-serif;
  text-align: left;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
}

.slabs {
  border: 0.800000011920929px solid #b2b2c9;
  border-radius: 7px;
  padding: 0.5% 5%;
  font-size: 26px;
  width: 124px;
  height: 50px;
}

.giftslabs {
  border-radius: 7px;
  margin: 4px 4px 33px 16px;
  font-size: 19px;
  padding: 6px 28px;
  width: 206px;
  height: 42px;
  text-align: left;
  border: 0.800000011920929px solid #b2b2c9;
}

.row.btnclass {
  margin-top: 25px;
  border-top: 5px solid aliceblue;
  padding: 10px;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}


.ml-33 {
  margin-left: 29rem !important;
}

.horizontal-border {
  border-top: 3px solid black;
  margin: 24px 0;
}

.plusbtn {
  border: none;
  font-weight: bold;
  font-size: 15px;
  width: 100%;
  text-align: center;
}

.giftipoints {
  width: 174px;
  text-align: center;
  background: #ffff;
  padding: 15px;
  color: #00b2ae;
  font-family: "Cairo-Bold", sans-serif;
  font-size: 18px;
  border-radius: 6px;
}

.custom-input {
  border: 0.800000011920929px solid #b2b2c9;
  border-radius: 10px;
  opacity: 1;
  width: 100px;
  height: 37px;
}

.productdescription {
  font-family: "Open Sans", sans-serif;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0;
  color: #000000;
  opacity: 0.7;
}

.nav {
  border: solid 0.5px #e1e1ea;
  border-radius: 5px;
  background-color: #e1e1ea;
}

.nav a {
  color: black;
}

.alert-info {
  color: #000000;
  background-color: #eeeef2;
  border-color: #eeeef2;
  font-family: "Cairo-semibold", sans-serif;
  text-align: center;
  font-size: 20px;
}

.profile-btn {
  font-family: "Cairo-semibold", sans-serif;
  font-size: 90%;
  padding: 0.5rem 7rem !important;
}
.profile-btn2 {
  font-family: "Cairo-semibold", sans-serif;
  font-size: 90%;
  padding: 0.5rem 4rem !important;
  width: 0;
}

.profile-btn.width-limit {
  max-width: 100%;
}

.userfont-dropdown {
  font-family: "Cairo-semibold", sans-serif;
  font-size: 14px;
}

.nav-userprofile {
  color: #808080;
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 25px;
}

.nav-userprofile:hover {
  color: #000000;
  border-bottom: 3px solid #000;
}

.nav-userprofile:focus {
  color: #000;
  border-bottom: 3px solid #000;
}

.active {
  color: #000000;
}

input.redeem-button-a.form-control {
  height: auto;
}

.userprofile-box-info {
  border-color: #e1e1ea;
  font-family: "Cairo-semibold", sans-serif;
  text-align: center;
  font-size: 20px;
}

.userprofile-box {
  position: relative;
}

.border-line {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 24px;
}

.dropdown-menu {
  padding: 0;
}

.rewardspoints-font {
  font-family: "OpenSans-Bold", sans-serif;
}

.redeem-button {
  height: 35%;
}

.redeem-button-a {
  height: 65px;
  border-radius: 8px;
}

.redeem-button-b {
  height: 65px;
  width: 100px;
  border-radius: 8px;
}

.cardpoints {
  width: 94%;
  height: 215px;
  background-color: #efeff3 !important;
  border-radius: 8px;
}

.font-points {
  font-family: "OpenSans-SemiBold", sans-serif;
}

.point-a {
  font-family: "OpenSans-Bold", sans-serif;
  color: #00b2ae;
  font-size: 39px;
}

.points-b {
  font-family: "Cairo-SemiBold", sans-serif;
}

.border {
  border-radius: 10px !important;
}

.table-head {
  background-color: #efeff3;
  opacity: 1;
  font-family: "Cairo-Bold", sans-serif;
}

.trans-detail {
  font-family: "Cairo-Bold", sans-serif;
}

.table-body-font {
  font-family: "Cairo-Bold", sans-serif;
  color: #393939;
}

.font-date {
  font-family: "Cairo-Bold", sans-serif;
  font-size: 14px;
}

.amount-color {
  color: #00b2ae;
  font-family: "Cairo-Bold", sans-serif;
}

.font-balance {
  color: #00b2ae;
  text-align: left;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0;
  opacity: 1;
}

.font-balance-red {
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0;
  color: #f22462;
  opacity: 1;
}

.popup-btn {
  width: 55%;
}

.redio-forgot {
  display: flex;
  width: 100%;
}

.redio-forgot .form-group {
  width: 50%;
}

.forgot.form-group {
  text-align: end;
}

.fix-footer > div > div:nth-child(2) {
  z-index: 1;
}

.selected-card-box {
  padding-top: 50px;
}

#product .nav-link {
  width: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  margin-right: 0;
  font-weight: 700;
  border-bottom: 3px solid transparent;
  color: black;
  // opacity: 40%;
  padding: 0.5rem;
}

ul.list-group.list-group-flush {
  list-style: decimal;
  margin-left: 20px;
  color: #77838f;
}

ul.list-group.list-group-flush li,
.t-col-right .privacy-item-style {
  margin-bottom: 10px;
}

ul.list-group.list-group-flush li a,
.t-col-right .privacy-item-style a {
  color: #77838f;
  font-size: 12px;
}

ul.list-group.list-group-flush li a:active {
  color: #3f7aec;
}

.t-col-left p {
  color: #77838f;
  font-size: 15px;
}

.sticky-side {
  box-shadow: 0 2px 48px #0000000f;
  background: #fff;
  border-radius: 4px;
}

#product .nav-link:hover {
  border-bottom: 3px solid black !important;
  color: black !important;
}

#product a.nav-link.active {
  border-bottom: 3px solid black !important;
  color: black !important;
  background: none;
}

.navbar-brand button {
  padding-left: 0;
  padding-right: 30px;
}

.navbar-brand {
  margin: 0;
  padding: 0 !important;
}

.button-icon svg {
  stroke: #d4d3d3 !important;
  fill: #fff !important;
  background: #3f7aec;
  height: 40px;
  width: 40px;
  padding: 6px;
  border-radius: 5px;
}

.contact-adrs {
  display: flex;
  margin-bottom: 20px;
}

.contact-adrs .button-icon {
  margin-right: 10px;
  margin-top: 8px;
}

.contact-adrs .lableStyle {
  font-size: 13px;
  color: #77808b;
  margin: 0;
}

.contact-form {
  background: #ffffff;
  box-shadow: 0 0 20px #00000029;
  max-width: 400px;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
}

.contact-adrs .textContent {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.account-container {
  padding: 10px;
  border: 0.8px solid #b2b2c9;
  border-radius: 8px;
}

span.frequently-asked-input.enqpage-search {
  text-align: center;
  position: relative;
  right: 0;
  border: 1px solid #ced4da;
  padding-top: 6px;
  border-left-color: transparent;
  padding-left: 10px;
  padding-right: 10px;
  background: #fff;
  border-radius: 0 5px 5px 0;
}

.navbar {
  padding: 20px 40px;
}

.navbar button.nav-btn-link img {
  width: 32px;
}

.navbar {
  justify-content: center;
}

.allGiftCard .products_icons {
  min-height: 38px;
}

.icon-margin .col-sm {
  display: FLEX;
  justify-content: CENTER;
}

.icon-margin {
  padding: 0;
  justify-content: SPACE-BETWEEN;
}

.card_images {
  display: contents;
  text-align: center;
}

.gificards {
  padding-top: 25px;
}

.gificards .card_images {
  width: 25%;
  padding: 20px 15px;
  overflow: hidden;
  display: inline-block;
}

.gificards .card_images img.imgcards {
  margin: 0 !important;
  width: 100%;
  height: auto;
}

.swal-footer {
  display: inline-table;
  text-align: justify;
  margin: 26px 0;
}

.category-btn {
  background: transparent;
  width: 100%;
  white-space: normal !important;
  max-width: 200px;
}

.category-btn.active {
  background: #00B2AE !important;
  border: none;
}

.swal-text {
  font-family: "Open Sans", sans-serif !important;
  text-align: center;
  font-size: 20px !important;
  letter-spacing: 0;
  display: block;
  color: #000000 !important;
  opacity: 1;
}

.swal-title {
  font-size: 40px !important;
  font-family: "Cairo-Bold", sans-serif !important;
  color: #000000 !important;
}

.tab-content {
  .card {
    margin-bottom: 30px !important;
    border-bottom: 1px solid !important;
    border-color: #ddd !important;
    border-radius: 10px !important;
    font-family: "Open Sans", sans-serif;
  }

  .card-header {
    background-color: transparent;
    border-bottom: 0;
    color: #636d79;
    font-weight: bold;
    cursor: pointer;
  }

  .card-body {
    color: #a2a9b0;

    strong {
      color: #333;
    }
  }
}

.swal-text {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0;
  display: block;
  color: #000000 !important;
  opacity: 1;
  font-size: 20px !important;
}

.swal-title {
  font-size: 40px !important;
  font-family: "Cairo-Bold", sans-serif !important;
  color: #000000 !important;
}

@media screen and (max-width: 1600px) {
  .pl-3.navbar-nav {
    padding: 0 !important;
  }
  nav.navbar .btn {
    font-size: 14px;
  }
  .navbar-brand img {
    max-width: 170px;
  }
  .gificards .card_images {
    padding: 15px 15px;
  }
}

.navbar .p-dropdown-trigger {
  width: 1.3rem;
}

@media screen and (max-width: 1400px) {
  .navbar .p-dropdown-trigger {
    width: auto !important;
  }
  .navbar-brand button {
    padding-right: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .gificards .card_images {
    width: 33.33%;
  }
}

@media screen and (max-width: 991px) {
  .gificards .card_images {
    width: 33.33%;
  }
}

@media screen and (max-width: 768px) {
  .gificards .card_images {
    width: 50%;
  }
  .profile-card {
    margin: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .gificards .card_images {
    width: 100%;
  }
}

@media screen and (max-width: 1365px) and (min-width: 1024px) {
  .gifticard-text {
    font-size: 34px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1024px) {
  .rec-arrow-right {
    right: 8px;
  }
}

@media screen and (max-width: 667px) {
  .giftslabs {
    margin: 10px;
    width: 100%;
    height: 42px;
  }
  .select-card-button {
    width: 100px;
    margin: 5px !important;
  }
  .selected-card-details {
    padding-top: 30px;
  }
  .rec-arrow-right {
    right: 10px;
  }
  .cardgifiti-startgf form .row {
    display: block !important;
  }
  .startgf-fields-text {
    padding: 0 15px;
  }
  .gifticard-text {
    font-size: 30px;
  }
  .startgf-text {
    font-size: 30px;
  }
  .giftiallcard-text {
    font-size: 20px;
  }
  .login-text {
    font-size: 22px;
  }
}

@media screen and (min-width: 1276px) {
  .category-btn {
    width: 85%;
  }
}

.all-gift-card-container {
  padding: 0 5em !important;
}

.social {
  color: #544e5d;
}

.social :hover {
  color: #00b2ae;
}

.social :link {
  background-color: #e6e6e6;
  border-radius: 14px;
  width: 58px;
  height: 58px;
}

.contact_icons {
  width: 50px;
  height: 50px;
}

.footer_icons {
  width: 20px;
  height: 22px;
  margin-right: 10px;
}

.g-modal {
  font-family: "Cairo-Bold", sans-serif;
  font-style: normal;
}

.go-back-to-homepage-box {
  background-color: #ededf1;
  border-radius: 12px;
  font-family: "Cairo-Bold", sans-serif;
  font-style: normal;
}

.mobile-view {
  display: none;
}

button.nav-btn.mr-2.text-white.btn.btn-info {
  padding: 9px 30px;
  background-color: #00b2ae;
  box-shadow: 0 0 15px #00000033;
  border-radius: 5px;
}

button.nav-btn.mr-2.text-white.btn.btn-primary {
  background: #3f7aec 0 0 no-repeat padding-box;
  box-shadow: 0 0 15px #00000033;
  border-radius: 5px;
  padding: 9px 17px;
}

.giftsome1 {
  max-width: 498px;
}

.giftsomeone {
  max-width: 498px;
  border: 0.800000011920929px solid #b2b2c9;
  border-radius: 10px;
}

.btnclasss {
  @include for-phone-only {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.btnclasss.mr-3.btn-group {
  border: 1px solid #b2b2c9;
  border-radius: 10px;
  opacity: 1;
  align-items: center;
}

.themetemplates {
  position: relative;
}

.p-paginator.p-component.p-paginator-bottom {
  float: right;
}

button.p-paginator-first.p-paginator-element.p-link {
  display: none;
}

button.themetemplates.btn-link {
  width: fit-content;
  margin: 0 4px;
  padding: 0;
  border-radius: 10px;
  border: 2px solid #fff;
}

button.p-paginator-last.p-paginator-element.p-link {
  display: none;
}

button.themetemplates.btn-link:hover,
button.themetemplates.btn-link.active {
  border: 2px solid #59cdca;
}

button.themetemplates.btn-link img {
  // width: 100%;
  height: auto;
  display: inline-block;
}

#start-gifting-container {
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      background-color: #808080;
      border-radius: 80px;
      margin: 5px;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #00b2ae;
    }
  }
}

button.themetemplates.btn-link.active::before {
  content: "";
  background-color: #59cdca;
  border: 0;
  width: 24px;
  height: 24px;
  border-radius: 0;
  display: inline-block;
  position: absolute;
  margin: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
}

button.themetemplates.btn-link.active::after {
  content: "\2713";
  font-size: 21px;
  line-height: 1;
  position: absolute;
  bottom: 2px;
  right: 3px;
  color: #fff !important;
}

.nav #product {
  padding: 0 40px;
}

.btn:focus {
  box-shadow: unset !important;
}

.btn-link:hover {
  background-color: #fff;
}

@media (max-width: 768px) {
  .mobile-view {
    display: flex;
    align-items: center;
  }
  span.desktop-view .btn-group {
    display: none;
  }
  .fix-footer.row .col-md-5.d-flex {
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .fix-footer.row .col-md-5.d-flex .d-flex {
    align-items: center;
  }
  .fix-footer.row small.amttext {
    font-size: 14px;
  }

}

.mobile-view {
  @include for-phone-only {
    display: flex;
    justify-content: flex-end;
  }
}

.phone-num-mask {
  padding: 8px 40px !important;
  width: 100%;
  font-family: inherit !important;
  -moz-appearance: textfield;
  @include for-small-desktop {
    padding: 8px 25px !important;
  }
}

.country-code {
  height: 46px;
  padding-left: 50px;
  padding-right: 0;
  width: 35%;
  @include for-small-desktop {
    width: 45%;
    padding-left: 45px;
  }
  @include for-phone-only {
    width: 60%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.swal-modal {
  .swal-text {
    max-width: 100%;
    font-family: "Open Sans", sans-serif !important;
  }

  .swal-icon--success__ring {
    background: #00b2ae;
    border-color: #00b2ae;
  }

  .swal-icon--success__line {
    background-color: #fff;
    z-index: 3;
  }

  .swal-icon.swal-icon--error {
    background: #f22462;
    border-color: #f22462;
  }

  span.swal-icon--error__line {
    background: #fff;
  }
}

.swal-modal.reward-modal {
  max-width: 660px;
  width: 100%;
  padding: 25px;

  button.swal-button {
    min-width: 200px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #00b2ae;
    font-family: "Cairo-Bold", sans-serif !important;
  }

  button.swal-button.swal-button--cancel {
    background-color: #b2b2c9;
    color: #fff;
  }
}

textarea#message:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.3rem rgba(63, 122, 236, 0.25);
}

.swal-modal.notificationMessage {
  max-width: 660px;
  width: 100%;
  padding: 25px;

  .swal-footer {
    display: block;
    text-align: center;

    button.swal-button {
      width: 100%;
      display: block;
      max-width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: #00b2ae;
      font-family: "Cairo-Bold", sans-serif !important;
    }

    button.swal-button.swal-button--cancel {
      background-color: #b2b2c9;
      color: #fff;
    }
  }
}

.swal-modal.notificationMessage1 {
  button.swal-button {
    background-color: #00b2ae;
    font-family: "Cairo-Bold", sans-serif !important;
  }

  button.swal-button.swal-button--cancel {
    background-color: #b2b2c9;
    color: #fff;
  }
}

.homeBannerSlider .swiper-container {
  margin-bottom: 25px;
  padding-bottom: 50px;

  .swiper-pagination {
    bottom: 0;
  }
}


/* my styles */

/* navigation*/
.navigation-cart-icon {
  width: 27px;
  margin: 0 12px 0 20px;
  @include for-tablet-portrait {
    width: 13px;
    margin-left: 10px !important;
  }

  @include for-phone-only {
    margin-right: 8px;
  }
}

.navigation-cart-text {
  @include for-tablet-landscape {
    display: none;
  }
}

.navigation-logIn-icon {
  margin: 0 25px 0 12px;
  @include for-tablet-portrait {
    width: 12px;
    margin-right: 13px;
  }
  @include for-phone-only {
    display: none;
  }
}

.navigation-login-text {
  &:hover {
    text-decoration: none;
  }

  @include for-tablet-portrait {
    font-size: 10px !important;
    font: normal normal bold 10px/11px Cairo !important;
    letter-spacing: 0.23px;
    color: #11142D !important;
  }

  @include for-phone-only {
    margin-right: 9px !important;
  }
}

.nav-btn {
  box-sizing: border-box;
  opacity: 1;
  padding: 13px 17px;
  font-family: "Cairo-semibold", sans-serif;
  height: 2.8rem;
  border-radius: 10px;

  &:active,
  &:focus {
    border: none;
  }

  @include for-tablet-portrait {
    padding: 7px;
    height: 2.2rem;
  }
  @include for-phone-only {
    padding: 5px 13px;
    border-radius: 5px;
    font-size: 9px;
    min-height: 25px;
    height: 27px;
    margin-top: 0;
  }

  &.cart {
    padding: 0;
  }
}

/*cartBadge*/
#lblCartCount {
  position: absolute;
  top: 4px;
  right: 37px;
  text-decoration: none;
  font-size: 18px;
  background: #ff0000 !important;
  color: #fff;
  padding: 0 5px;

  cursor: pointer;
  @include for-tablet-landscape {
    border-radius: 8px;
    font-size: 16px;
    min-width: 20px;
    width: 20px;
    height: 17px;
    top: 5px;
    right: 3px;
  }

  @include for-tablet-portrait {
    border-radius: 6px;
    font-size: 11px;
    min-width: 15px;
    width: 15px;
    height: 12px;
    top: 12px;
    right: 2px;
  }

  @include for-phone-only {
    top: 3px;
    width: 13px;
    right: 0;
    min-width: 13px;
  }
}

.navbar-brand {
  &.logoIcon {
    img {
      height: 60px !important;
      @include for-tablet-portrait {
        height: 26px !important;
        width: 26px !important;
        padding-bottom: 2px;
      }
    }
  }
}

.dropdown-toggle:after,
.pi:before,
{
  display: inline-block;
  margin-left: 0.255em;
  margin-right: 0;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
  color: black;
  transform: rotate(0);
  line-height: normal;
  font-size: medium;

  @include for-tablet-portrait {
    font-size: 10px;
  }
}

.header-location {
  margin-left: 62px;
  @include for-tablet-landscape {
    margin-left: 16px;
  }
}

.location-dropdown-text {
  padding-right: 35px;
  @include for-phone-only {
    padding-right: 16px !important;
  }
}

.location-dropdown-icon {
  @include for-tablet-portrait {
    position: relative;
    top: 8px;
    width: 5px;
    height: 11px;
  }
}

.p-dropdown-trigger-icon {
  @include for-tablet-portrait {
    padding-left: 10px;
  }
}

.navbar-light {
  @include for-phone-only {
    box-shadow: none;
  }
}

/* Home Banner HomeSlider */

.homeBannerSlider {
  width: 100%;
  @include for-tablet-landscape {
    margin: 0 auto 12px;
  }
  @include for-phone-only {
    margin: 0;
    padding: 0;
  }

  .cardgifti {
    justify-content: space-between;
    padding: 0 150px;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    --tblr-gutter-x: 0;
    background-color: rgb(239, 248, 248,);
    min-height: 621px;
    @media screen and (min-width: 1441px) {
      justify-content: space-evenly;
    }
    @media screen and (max-width: 1400px) {
      padding: 0 100px;
      min-height: unset;
    }
    @include for-small-desktop {
      padding: 0 20px 0 50px;
    }
    @include for-tablet-landscape {
      //height: unset;
      //min-height: unset;
      padding: 50px 40px 40px;
      flex-direction: column;
      align-items: center;
    }
    @include for-tablet-portrait {
      padding: 40px;
    }
    @include for-phone-only {
      //height: 479px;
      padding: 0;
      border-radius: 0;
    }

    .homeBanner {
      padding: 100px 0 100px;
      width: 40.666667%;
      @media screen and (max-width: 1400px) {
        padding: 80px 0 60px;
      }

      @include for-tablet-landscape {
        width: 100%;
        padding: 15px 0 30px;
      }
      @include for-phone-only {
        padding-top: 14px;
      }

      .gifticard-text {
        font-family: "Cairo-Bold", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        color: #11142d;
        letter-spacing: 0;
        line-height: 72px;
        @include for-small-desktop {
          font-size: 45px;
          line-height: 60px;
        }
        @include for-tablet-landscape {
          font-size: 36px;
          line-height: 48px;
          text-align: center;
        }
        @include for-tablet-portrait {
          font-size: 30px;
          line-height: 42px;
          padding: 0 10px;
        }
        @include for-phone-only {
          font-size: 30px;
          line-height: 40px;
          text-align: center;
          padding: 0 40px;
          margin-bottom: 0;
        }
      }

      .gifticard-text-sub {
        padding-top: 15px;
        padding-right: 4rem;
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 15px;
        font-family: "Open Sans", sans-serif;
        color: #11142D;
        display: block;
        margin-bottom: 1rem;
        @include for-small-desktop {
          font-size: 18px;
          line-height: 26px;
          padding-right: 1.5rem;

        }
        @include for-tablet-landscape {
          font-size: 16px;
          line-height: 26px;
          padding: 5px 0;
          text-align: center;
        }
        @include for-tablet-portrait {
          font-size: 10px;
          line-height: 20px;
          padding: 10px 14px 0 14px;
        }
      }

      .card-text-btn {
        display: flex;
        justify-content: flex-start;
        margin-top: 58px;
        @include for-tablet-landscape {
          justify-content: center;
        }
        @include for-phone-only {

        }

        .startGifting {
          border-radius: 10px;
          font-size: 18px;
          line-height: 22px;
          padding: 13px 25px;
          margin-right: 17px;
          @include for-tablet-landscape {
            font-size: 16px;
            line-height: 20px;
            padding: 8px 18px;
          }
          @include for-tablet-portrait {
            border-radius: 5px;
            font-size: 14px;
            padding: 8px 15px;
            max-height: 35px;
          }
          @include for-phone-only {
            font-size: 9px;
            padding: 5px 13px;
            max-height: 30px;
          }
        }

        .howItWorks {
          border-radius: 10px;
          font-size: 18px;
          line-height: 22px;
          padding: 13px 25px;
          @include for-tablet-landscape {
            font-size: 16px;
            line-height: 20px;
            padding: 8px 18px;
          }
          @include for-tablet-portrait {
            border-radius: 5px;
            font-size: 14px;
            padding: 8px 15px;
            max-height: 35px;
          }
          @include for-phone-only {
            font-size: 9px;
            padding: 5px 13px;
            max-height: 30px;
            img {
              max-height: 20px;
            }
          }
        }
      }
    }
  }

  .homeBannerImage {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: 700px;
    @media (max-width: 1600px) {
      max-width: 600px;
      //width: 100%
      //margin-top: 30px;
    }
    @include for-tablet-landscape {
      //width: 100%
      //margin-top: 30px;
    }
    @include for-phone-only {
      flex: 1;
      padding: 0 50px;
    }

    .gifti-global-img {
      width: 100%;
      height: auto;
      transform: scale(1.3);
      @include for-small-desktop {
        flex: 1 !important;
        transform: scale(1.2);
      }
      @include for-tablet-landscape {
        transform: scale(1);
      }
    }
  }
}

.icon-box {
  @include for-tablet-portrait {
    padding: 30px 0;
  }

  .icon {
    img {
      max-width: 100%;
    }
  }

  .icon-item-container {
    @media screen and (max-width: 1440px) {
      width: 33.3%;
    }
    @include for-phone-only {
      width: 100%;
    }

    .icon-text {
      font-size: 16px;
      line-height: 22px;
      text-align: left;
      @include for-tablet-landscape {
        font-size: 14px;
        line-height: 18px;
      }
      @include for-tablet-portrait {
        font-size: 12px;
        line-height: 15px;
      }
      @include for-phone-only {
        text-align: center;
        padding: 10px 40px 0;
      }
    }
  }
}

/* category slider */

.category-slider-item {
  width: 172px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.marked {
  font-weight: bold;
  color: #042D49;
}

.giveaway {
  z-index: 1;
  max-width: 100%;
  margin: 0;
  justify-content: center;

  @include for-large-desktop {
    flex-direction: column;
  }

  &.hidden {
    display: none;
  }
}

.giveaway-static {
  max-width: 720px;
  background: #14a49e url('./assets/media/images/giveaway/imaBoston/theTown.svg') center bottom no-repeat padding-box;
  background-size: auto;
  padding: 120px 100px 0;

  @include for-large-desktop {
    padding: 25px 30px 0;
    margin: 0 auto;
    max-width: 100%;
    background-size: cover;
    background-position: center bottom;
  }

  & > img {
    @include for-large-desktop {
      width: 239px;
      height: 47px;
    }
  }

  & > .title {
    margin: 60px 0 90px;
    font: normal bold 36px/46px Cairo;
    text-align: left;
    color: #FFFFFF;

    @include for-large-desktop {
      margin: 40px 0 30px;
      text-align: center;
      font: normal normal bold 22px/29px Cairo;
    }
  }

  .achievements {
    margin-bottom: 70px;
    display: flex;
    justify-content: space-between;

    @include for-large-desktop {
      margin-bottom: 37px;
    }
  }

  .achievement {
    img {
      width: fit-content;
      height: fit-content;
      padding-right: 15px;

      @include for-tablet-portrait {
        padding-right: 7px;
      }
    }

    .result {
      font: normal normal bold 22px/24px Cairo;
      color: #01B2AE;
      margin: 0;
      text-align: left;
      padding-top: 5px;

      @include for-tablet-portrait {
        font: normal normal bold 12px/18px Cairo;
        padding: 0;
      }
    }

    .title {
      font: normal normal normal 13px/20px Open Sans;
      color: #045855;
      margin: 0;
      text-align: left;

      @include for-tablet-portrait {
        font: normal normal normal 7px/7px Open Sans;
      }
    }
  }

  .explore-btn-wrapper {
    @include for-large-desktop {
      justify-content: center !important;
    }
  }

  .explore-btn {
    width: 182px;
    height: 50px;
    margin-bottom: 35px;
    background-color: #dd782f !important;

    & > p {
      margin: 0;
      font: normal normal bold 16px/16px Open Sans;
      padding-right: 15px;
    }
  }

  .learn-more {
    text-align: left;
    font: normal normal normal 17px/27px Open Sans;
    color: #dbe1e5;
    margin-bottom: 20px;

    @include for-large-desktop {
      text-align: center;
      font: normal normal normal 13px/23px Open Sans;
    }

    & > a {
      color: #dbe1e5;
    }
  }

  .footer-logos {
    float: right;
    max-width: 100px;
    margin-bottom: 75px;
    @include for-large-desktop {
      float: none;
      margin: 41px auto 41px;

      & .musettes {
        margin-right: 0;
      }
    }

    & > img:first-child {
      //margin-right: 50px;

      @include for-large-desktop {
        //margin-right: 30px;
      }
    }
  }

  .top-logos {
    margin: 0 0 0px;

    @include for-large-desktop {
      margin: 41px 0 43px;

      & > img:first-child {
        width: 123px;
        height: 38px;
      }

      & > img:last-child {
        width: 167px;
        height: 63px;
      }
    }

    & > img:first-child {
      margin-right: 50px;

      @include for-large-desktop {
        margin-right: 30px;
      }
    }
  }
}

.giveaway-dynamic {
  max-width: 740px;
  padding: 60px 111px 60px;

  @include for-large-desktop {
    margin: 0 auto;
    padding: 0;
  }
}

.giveaway-form {

  @include for-large-desktop {
    max-width: 496px;
    margin: 20px auto 55px;
  }

  @include for-phone-only {
    margin: 20px 25px 55px;
  }

  button {
    height: 72px;

    @include for-tablet-landscape {
      height: 50px;
      font: normal normal bold 14px/14px Open Sans;
    }
  }

  .title {
    color: #042D49;
    margin-bottom: 20px;
    font-family: 'Gotham Black', sans-serif;
    font-style: normal;
    line-height: 40px;
    font-size: 30px;

    @include for-tablet-landscape {
      font: normal normal bold 20px/24px Open Sans;
      margin-bottom: 10px;
      text-align: center;
      font-family: "Gotham Rounded Bold", sans-serif;
    }

  }

  .subtitle {
    font: normal normal normal 18px/24px Open Sans;
    color: #042D49;
    margin: 0;
    font-family: 'Gotham Light', sans-serif;
    .font-weight-bold {
      font-family: "Gotham Rounded Bold", sans-serif;
    }
    @include for-tablet-landscape {
      font: normal normal normal 13px/18px Open Sans;
      text-align: center;
    }

  }

  .form-control {
    margin-top: 20px;

    @include for-tablet-landscape {
      margin-top: 13px;
      height: 42px;
      font: normal normal bold 12px Open Sans;

      &::placeholder {
        font: normal normal 600 12px Open Sans;
        color: #B7B7B7;
      }
    }
  }

  .g-recaptcha {
    margin: 25px 0 40px;

    @include for-tablet-landscape {
      margin: 16px 0 26px;
    }
  }

  .form-group .validation-messages {
    font-size: 10px;
    padding: 3px 0 0 5px;
    float: left;
  }

  input.form-control.is-valid {
    border-color: #01B2AE;
    background-image: unset;
  }

  select.form-select.is-valid {
    border-color: #01B2AE;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dadcde' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  }

  input.form-control.is-invalid {
    background-image: url('./assets/media/icons/alert-circle-icon.svg');
  }

  select.form-control.is-invalid {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dadcde' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url('./assets/media/icons/alert-circle-icon.svg');
  }
}

.giveaway-response {
  padding-top: 168px;
  text-align: center;

  @include for-large-desktop {
    padding: 0;
    margin: 35px auto 35px;
    max-width: 498px;
  }

  @include for-phone-only {
    margin: 35px 25px 35px;
  }

  & > img {
    margin-bottom: 45px;

    @include for-tablet-landscape {
      width: 120px;
      height: 117px;
      margin-bottom: 27px;
    }
  }

  & > p {
    font: normal normal bold 28px/48px Open Sans;
    margin-bottom: 50px;

    @include for-tablet-landscape {
      font: normal normal bold 18px/30px Open Sans;
      margin-bottom: 30px;
    }
  }

  & > button {
    height: 72px;
    font: normal normal bold 19px/21px Open Sans;

    @include for-tablet-landscape {
      height: 43px;
      font: normal normal bold 10px/11px Open Sans;
    }
  }
}

.splash-root {
  width: 100%;
  background-color: #FFFFFF;
  z-index: 5;
  opacity: 1;
  position: absolute;
  display: contents;
}

.splash-root.hidden {
  display: unset;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;

  .footer-line {
    width: 0;
    height: 0;
  }
}

.splash-screen {
  margin: 155px 110px 0;
  color: #183B56;
  height: 100%;

  @include for-tablet-landscape {
    width: 100%;
    margin: 0;
    padding: 30px 24px 0;
    text-align: center;

    & > .row {
      width: 100%;
    }

    .ima-europe-logo {
      width: 153px;
      height: 76px;
    }

    .splash-image {
      display: none;
    }
  }

  .splash-info {
    padding-left: 20px;

    @include for-tablet-landscape {
      padding: 0;
    }

    .successful-check-mark {
      @include for-tablet-landscape {
        width: 32px;
        height: 32px;
      }
    }

    .title {
      font: normal normal normal 56px/68px Open Sans;
      margin-top: 55px;
      padding-right: 190px;

      @include for-tablet-landscape {
        margin-top: 25px;
        font: normal normal normal 41px/50px Open Sans;
        padding: 0;
      }
    }

    .subtitle {
      font: normal normal normal 20px/36px OpenSans-Regular;
      margin-bottom: 40px;

      @include for-tablet-landscape {
        font: normal normal normal 14px/36px Open Sans;
      }

    }

    .date {
      font: normal normal bold 25px/34px OpenSans-Bold;
      margin: 18px 0;

      @include for-tablet-landscape {
        font: normal normal bold 19px/26px OpenSans-Bold;
        margin: 15px 0;
      }
    }

    .time {
      color: #5A7184;
      font: normal normal bold 25px/44px OpenSans-Regular;
      margin: 0;

      @include for-tablet-landscape {
        font: normal normal bold 19px/33px OpenSans-Regular;
      }
    }
  }

  .footer-text {
    text-align: center;
    margin: 112px 0 38px;
    font: normal normal normal 24px/32px Roboto-Medium;

    @include for-tablet-landscape {
      margin: 33px 0 99px;
      font: normal normal normal 14px/17px Roboto-Medium;
    }
  }

  .footer-line {
    height: 32px;
    margin: 0 20px 98px;
    background-color: #01B2AE;

    @include for-tablet-landscape {
      display: none;
    }
  }
}