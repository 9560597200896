.status {
  background: #3F7AEC 0 0 no-repeat padding-box !important;
  border-radius: 15px !important;
  text-align: center;
  font-family: 'Cairo-Bold', sans-serif;
  letter-spacing: 0 !important;
  font-size: 20px;
  max-width: 141px;
  color: #FFFFFF !important;
}

.img-thumbnail2 {
  background-color: #fff;
  border-radius: 0.25rem;
  max-width: 100%;
  height: 145px;
}

.p-datatable .p-datatable-tbody > tr > td :hover {
  background: #ffffff;
  color: unset !important;
  transition: box-shadow 0.2s;
  outline-color: #B2B2C9 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  background: #ffffff;
  color: unset !important;
  transition: box-shadow 0.2s;
  outline-color: #B2B2C9 !important;
}

.status1 {
  background: #00B2AE 0 0 no-repeat padding-box !important;
  border-radius: 15px !important;
  text-align: center;
  font-family: 'Cairo-Bold', sans-serif;
  letter-spacing: 0 !important;
  font-size: 20px;
  color: #FFFFFF !important;
}

.card_box {
  border: 0.800000011920929px solid #B2B2C9;
  border-radius: 11px;
  opacity: 1;
}

.order_box_card.container {
  border: 0.800000011920929px solid #B2B2C9;
  border-radius: 12px;
  opacity: 1;
  padding: 15px 42px 42px 42px;
  margin-bottom: 31px;
}

p.text_order {
  text-align: left;
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0;
  color: #393939;
  opacity: 1;
}

.gift_details {
  text-align: left;
  font: normal normal bold 30px/56px Cairo;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  font-size: 30px;
}

.text_card {
  text-align: left;
  letter-spacing: 0;
  color: #111111;
  opacity: 1;
  font-size: 11px;
  padding: 11px;
}

.image_style {
  border-radius: 20px 20px 0 0 !important;
  width: 100%;
}

.send_to {
  text-align: left;
  font: normal normal 600 20px/19px Cairo;
  letter-spacing: 0;
  color: #11142D;
  text-transform: uppercase;
  opacity: 1;
  font-size: 20px
}

.date_text {
  text-align: right;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0;
  color: #3B3B3B;
  opacity: 1;
  font-size: 14px;
  /* margin-top:42px; */
}

.quantity_text {
  text-align: center;
  letter-spacing: 0;
  color: #3B3B3B;
  opacity: 1;
  font-size: 20px;
}

.gift_title {
  text-align: left;
  font: normal normal 600 18px/14px Open Sans;
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
}

.card_title {
  font-size: 24px;
  text-align: left;
  font: normal normal bold 24px/19px Open Sans;
  letter-spacing: 0;
  color: #3B3B3B;
  opacity: 1;
}

.orderid_text {
  font-size: 20px !important;
  text-align: left;
  font: normal normal bold 20px/27px Open Sans;
  letter-spacing: 0;
  color: #3B3B3B !important;
  opacity: 1;
}

hr {
  margin-top: 0 !important;
  margin-bottom: 1rem;
  /* border: 0; */
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.order {
  text-align: left;
  font: normal normal bold 40px/36px Cairo;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
}

.p-datatable .p-datatable-thead > tr > th {
  color: #393939 !important;
  background: #EFEFF3 !important;
  font: normal normal bold 20px/37px Cairo !important;
  opacity: 1 !important;
}

.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
    display: unset !important;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    padding: .4rem;
    min-width: 30%;
    display: inline-block;
    margin: -.4em 1em -.4em -.4rem;
    font-weight: bold;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}
