@import "breakpoints";

@mixin for-phone-only {
  @media (max-width: $phone) { @content; }
}
@mixin for-tablet-portrait{
  @media (max-width: $tablet-portrait) { @content; }
}
@mixin for-tablet-landscape{
  @media (max-width: $tablet-landscape) { @content; }
}
@mixin for-small-desktop {
  @media (max-width: $desktop) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1199px) { @content; }
}
@mixin for-large-desktop {
  @media (max-width: $large-desktop) { @content; }
}
@mixin without-phone {
  @media (min-width: $phone-up) { @content; }
}
